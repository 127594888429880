html {
  font-family: 'Inter', sans-serif;
}

body {
  margin: 0;
  overflow-y: scroll;
  //background-color: #ecedef;
}

*,
:after,
:before {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

a {
  color: var(--primary);
  text-decoration: none;

  &:hover {
    color: var(--primary-h);
  }
}

hr {
  border: none;
  border-bottom: 1px solid #eee;
}
