.headline-1 {
  font-size: 36px;
  line-height: 44px;
  font-weight: 600;
  margin-top: 0;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.headline-3 {
  margin-top: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;

  &:not(:last-child) {
    margin-bottom: 1em;
  }
}

.lead-1 {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: var(--gray-700);
}
