@import 'global';
@import 'colors';
@import 'typography';
@import '../assets/fonts/stylesheet.css';

$container-max-widths: (
  sm: 540px,
  md: 744px,
  lg: 936px,
);

$grid-breakpoints: (
  xs: 0,
  sm: 480px,
  md: 768px,
  lg: 1024px,
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2,
  6: $spacer * 3,
);

@import '../../node_modules/bootstrap/scss/bootstrap-grid';

@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/utilities';
@import '../../node_modules/bootstrap/scss/utilities/api';
