:root {
  //Brand
  --primary: #18a0fb;
  --primary-h: #0080d5;

  //Text
  --text-default: #333;

  //Gray
  --gray-600: #8c8c8c;
  --gray-700: #767677;
  --gray-800: #585858;

  //
  --blue: #18a0fb;
  --green: #00aa4d;
  --orange: orange;
}
